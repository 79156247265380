/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                $('.js-nav-burger').click(function () {
                    $(this).toggleClass('is-active');
                    $('.nav-primary').toggleClass('is-active');
                });


                var animateHTML = function () {
                    var elems;
                    var windowHeight;
                    var windowMiddle;

                    checkPosition = function() {
                        for (var i = 0; i < elems.length; i++) {
                            var positionFromTop = elems[i].getBoundingClientRect().top;
                            if (positionFromTop - windowMiddle - 100 <= 0) {
                                elems[i].classList.remove('on-scroll-hidden');
                                /*
                                elems[i].className = elems[i].className.replace(
                                    'hidden',
                                    'fade-in-element'
                                );
                                */
                            }
                        }
                    };
                    addEventHandlers = function () {
                        window.addEventListener('scroll', checkPosition);
                        window.addEventListener('resize', init);
                    };

                    init = function () {
                        elems = document.querySelectorAll('.on-scroll-hidden');
                        windowHeight = window.innerHeight;
                        windowMiddle = windowHeight / 2;
                        addEventHandlers();
                        checkPosition();
                    };


                    return {
                        init: init
                    };
                };
                animateHTML().init();


            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
